<template>
  <BaseModal
    name="Upload-Editor"
    id="Upload-Editor"
    idModal="Upload-Editor"
    size="lg"
    title="Selecione sua imagem"
    @shown="restCurent()"
  >
    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border padding"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <b-tab :title="`Enviar arquivos`" active>
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <Uploader
            :postAction="returnApi() + 'midia/upload'"
            @update="fetchMidia('', 'selected')"
          />
        </b-container>
      </b-tab>

      <b-tab :title="`Biblioteca de mídias`">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <div class="flex">
            <div class="container-left">
              <div class="container-pesquisa">
                <div style="position: relative">
                  <!-- <h4 class="text-filter">Pesquisar mídia</h4> -->
                  <div class="inputSearch">
                    <img
                      src="@/assets/img/icons/search.svg"
                      class="Pointer searchIcon"
                    />
                    <input
                      type="text"
                      placeholder="Pesquisar itens de mídia"
                      v-model="search_img"
                      @input="debounce"
                      class="input-busca"
                    />
                  </div>
                </div>
              </div>
              <div class="container-img">
                <div class="imgs" v-for="(img, index) in midia" :key="index">
                  <img
                    v-if="
                      (img.type && img.type === 'image/jpeg') ||
                      img.type === 'image/png' ||
                      img.type === 'image/gif' ||
                      img.type === 'image/webp'
                    "
                    :src="img.url"
                    alt="img"
                    @click="selecionarImg(img)"
                    @error.once="setAltImg"
                  />
                  <img
                    v-else
                    @click="selecionarImg(img)"
                    src="@/assets/icons/pdf_icon.svg"
                    alt="pdf"
                  />
                </div>
              </div>
              <b-row>
                <b-col>
                  <Paginate
                    v-if="midia.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="container-right">
              <div class="grid-edit">
                <div>
                  <img
                    class="img-style"
                    v-if="
                      (selected_img.type &&
                        selected_img.type === 'image/jpeg') ||
                      selected_img.type === 'image/png' ||
                      selected_img.type === 'image/gif'
                    "
                    :src="selected_img.url"
                    :alt="selected_img.name"
                  />
                  <img
                    v-else
                    class="img-conteudo img-style"
                    src="@/assets/img/icons/img_null.svg"
                    alt="imagem-vazia"
                  />
                </div>
                <div>
                  <b-row style="margin-top: 1rem">
                    <b-col cols="12" md="12">
                      <b-form-group label="Nome da mídia" label-for="name">
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="selected_img.name"
                          type="text"
                          placeholder="..."
                          v-validate="'required'"
                          disabled
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="!errors.has('selected_img.post_title')"
                        >
                          Por favor, insira o nome
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group label="URL da mídia" label-for="url_midia">
                        <b-form-input
                          id="url_midia"
                          name="url_midia"
                          v-model="selected_img.url"
                          type="text"
                          placeholder="..."
                          disabled
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group
                        label="Tipo da mídia"
                        label-for="type_midia"
                      >
                        <b-form-input
                          id="type_midia"
                          name="type_midia"
                          v-model="selected_img.type"
                          type="text"
                          placeholder="..."
                          disabled
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
      </b-tab>
    </b-tabs>

    <div
      key="loading"
      v-if="loading"
      class="py-5 my-5 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"> </b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="info" :disabled="loading" @click="mandarImgSelected">
        Selecionar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Uploader from "@/components/Midias/Uploader";
//
import _ from "lodash";
import Paginate from "@/components/Paginate";
//
import MidiaListaService from "@/services/resources/MidiaListaService";
const serviceMidiaLista = MidiaListaService.build();
//
import FileUpload from "vue-upload-component";

export default {
  components: {
    FileUpload,
    Uploader,
    Paginate,
  },
  data() {
    return {
      midia: [],
      search_img: "",
      selected_img: [],
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
    };
  },
  methods: {
    restCurent(){
        this.pagination.currentPage = 1
      },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchMidia(this.search_img);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchMidia(this.search_img);
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    returnApi() {
      return process.env.VUE_APP_API_HOST;
    },
    mandarImgSelected() {
      this.$emit("setImg", this.selected_img.url);
      this.$bvModal.hide("Upload-Editor");
    },
    selecionarImg(img) {
      this.selected_img = img;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchMidia(this.search_img);
    }, 500),
    async fetchMidia(search = null, selected) {
      this.loading = true;
      this.pagination.totalPages = 1;
      var data = {
        page: this.pagination.currentPage,
      };

      if (this.search_img) {
        data.search = this.search_img;
      }
      await serviceMidiaLista
        .search(data)
        .then((resp) => {
          this.midia = resp.data;
          this.pagination.totalPages = resp.last_page;
          if (selected) {
            this.selected_img = resp.data[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchMidia();
  },
};
</script>

<style lang="scss" scoped>
#name:disabled,
#url_midia:disabled,
#type_midia:disabled {
  color: #a09d9d !important;
}
.img-style {
  width: 100%;
  word-break: break-all;
  height: 145px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid rgba(161, 161, 161, 0.192);
}
.flex {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.container-right {
  padding: 20px;
}
.inputSearch {
  position: relative;
}
.input-busca {
  width: 100%;
  height: 45px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.searchIcon {
  position: absolute;
  left: 15px;
  bottom: 26px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.text-filter {
  font-size: 12px;
}
.container-pesquisa {
  display: flex;
  // justify-content: flex-end;
  align-items: center;
}
.imgs img {
  height: 110px;
  width: 110px;
  cursor: pointer;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid rgba(161, 161, 161, 0.192);
}
.container-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: hidden;
  height: 360px;
  gap: 5px;
}
.newTextDiv {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--fontcolor);
  margin-top: 10px;
  margin-bottom: 10px;
}
.newBtn {
  border: none !important;
  background: var(--greenn) !important;
  color: #fff !important;
  outline: none !important;
  font-weight: 600 !important;
  height: 60px !important;
  padding: 0 42px !important;
  border-radius: 10px !important;
  font-size: 14px !important;

  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
  line-height: 60px !important;

  /* width: 50% !important; */
  margin: 0 auto !important;
  margin-bottom: 11px !important;
}

.container-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>

<template>
  <BaseModal
    name="ModalCriarRemetentes"
    id="ModalCriarRemetentes"
    idModal="ModalCriarRemetentes"
    size="lg"
    title="Criar remetente"
  >
    <div class="grid">
      <div class="email">
        <h4 class="titulo">E-mail</h4>
        <input type="email" placeholder="Digite seu e-mail" v-model="email" />
      </div>
      <div class="nome">
        <h4 class="titulo">Nome</h4>
        <input type="text" placeholder="Digite seu nome" v-model="nome" />
      </div>
      <div class="telefone">
        <h4 class="titulo">Telefone</h4>
        <input
          type="text"
          placeholder="Digite o numero do telefone"
          v-model="phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-validate="'required'"
        />
      </div>
      <div class="site">
        <h4 class="titulo">Site</h4>
        <input type="text" placeholder="Digite seu site" v-model="site" />
      </div>
      <div class="ocupacao">
        <h4 class="titulo">Ocupação</h4>
        <input type="text" placeholder="Digite a ocupação" v-model="ocupacao" />
      </div>
    </div>

    <div v-if="!isSendgrid" style="width: 50%;">
      <div class="feedback">
        <b-form-checkbox
          v-model="feedback"
          name="is_main"
          size="lg"
          switch
          >
        </b-form-checkbox>
        <h4 class="titulo" style="margin-bottom: -4px !important">Feedback por e-mail</h4>
      </div>
      <p style="font-size: 13px;margin-top: 5px; color: #81858e !important;">Receba feedback de e-mails que não foram possíveis serem enviados ou denunciados como spam</p> 
    </div>
    
    <template v-slot:footer="{}">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  props:{
    isSendgrid:Boolean
  },
  data() {
    return {
      nome: "",
      email: "",
      phone: "",
      site: "",
      ocupacao: "",
      loading: false,
      feedback:false,
    };
  },
  methods: {
    onSubmit() {
      if (this.email.length === 0) {
        this.$grToast.toast("Obrigatório preencher o e-mail", {
          title: "Criar remetente",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      if (this.nome.length === 0) {
        this.$grToast.toast("Obrigatório preencher o nome do produto", {
          title: "Criar remetente",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      if (this.phone.length === 0) {
        this.$grToast.toast("Obrigatório preencher o telefone", {
          title: "Criar remetente",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      if (this.site.length === 0) {
        this.$grToast.toast("Obrigatório preencher o site", {
          title: "Criar remetente",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      if (this.ocupacao.length === 0) {
        this.$grToast.toast("Obrigatório preencher a ocupacao", {
          title: "Criar remetente",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      var data = {
        id: "sender/create",
        name: this.nome,
        email: this.email,
        phone: this.phone,
        occupation: this.ocupacao,
        email_feedback_forwarding:this.feedback,
        site: this.site,
      };
      this.loading = true;
      serviceSettings
        .createId(data)
        .then((resp) => {
          if (resp.message === "Remetente já cadastado!") {
            this.$grToast.toast(resp.message, {
              title: "Criar remetente",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }

          this.nome = "";
          this.email = "";
          this.phone = "";
          this.site = "";
          this.ocupacao = "";
          this.feedback = false
          this.$grToast.toast("Remetente criado com sucesso!", {
            title: "Criar remetente",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$bvModal.hide("ModalCriarRemetentes");
          this.$emit("verifyAddress", resp.sender);
          this.$emit("update");
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao integrar", {
            title: "Criar remetente",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.nome = "";
      this.email = "";
      this.phone = "";
      this.site = "";
      this.ocupacao = "";
      this.feedback = false;
      this.$bvModal.hide("ModalCriarRemetentes");
    },
  },
};
</script>

<style scoped>
.foto {
  margin-top: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 25px;
}
input {
  width: 100%;
}
.flex {
  display: flex;
  gap: 15px;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
.feedback{
  display: flex !important;
  align-items: center;
}
</style>

<template>
  <b-tab
    :title="`Remetentes`"
    :active="$route.query.tab === 'remetentes'"
    @click="setQuery('remetentes')"
    @click.once="fetch()"
  >
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
      <div class="container-titulo">
        <h5 class="my-3">Configuração de remetentes</h5>
        <BaseButton variant="primary" @click="openModal('ModalCriarRemetentes')"
          >Novo remetente
        </BaseButton>
      </div>

      <b-row class="Table-body mb-1">
        <b-col cols="3" class="d-none d-md-block break">Nome</b-col>
        <b-col cols="4" class="d-none d-md-block break">E-mail</b-col>
        <b-col cols="2" class="d-none d-md-block break">Status</b-col>
        <b-col cols="2" class="d-none d-md-block break">Data de criação</b-col>
        <b-col cols="1" class="d-none d-md-block break">Ações</b-col>
      </b-row>
      <b-row
        v-if="!loading && !remetentes.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum remetente foi encontrado</p>
      </b-row>
      <b-row
        class="Table-body"
        v-for="(sender, index) in remetentes"
        :key="index"
      >
        <b-col cols="3">
          <div>
            <p class="break">{{ sender.name }}</p>
          </div>
        </b-col>
        <b-col cols="4">
          <div>
            <p class="break">
              {{ sender.email ? sender.email : "Sem e-mail" }}
            </p>
          </div>
        </b-col>
        <b-col cols="2">
          <div>
            <p class="break status-sender" :class="{'active-status' : sender.status == 'active'}">
              {{ sender.status === "active" ? "Verificado" : "Não verificado" }}
            </p>
          </div>
        </b-col>
        <b-col cols="2">
          <div>
            <p class="break">{{ sender.created_at | datetime }}</p>
          </div>
        </b-col>
        <b-col cols="1" class="d-flex">
          <div>
            <b-dropdown id="dropForm" right style="margin-top: 12px">
              <template #button-content>
                <img src="@/assets/img/icons/dots.svg" alt="dots" />
              </template>
              <b-dropdown-item
                @click="openModal('ModalEditarRemetente', sender)"
                >Editar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="sender.status !== 'active'"
                @click="sendToVerification(sender)"
                >Verificar status</b-dropdown-item
              >
              <b-dropdown-item @click="DeletarRemetente(sender.id)"
                >Deletar</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </b-col>
      </b-row>

      <ModalCriarRemetentes @verifyAddress="sendToVerification($event)" :isSendgrid="isSendgrid" @update="fetch()" />
      <ModalEditarRemetente :selectedRemetente="selectedRemetente" :isSendgrid="isSendgrid"/>

      <b-row class="mt-2">
        <!-- <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary">Salvar alterações</BaseButton>
        </b-col> -->
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-row>
      <b-col>
        <Paginate
          v-if="remetentes.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>
    <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
    <ModalValidarRemetente @getSenders="fetch(1)" :isSendgrid="isSendgrid" :sender="selectedSender"/>
  </b-tab>
</template>

<script>
import ModalCriarRemetentes from "@/components/Configuracoes/Modal/ModalCriarRemetentes";
import ModalEditarRemetente from "@/components/Configuracoes/Modal/ModalEditarRemetente";
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
import Paginate from "@/components/Paginate";
import Vue from "vue";
import EmailDomainService from "@/services/resources/EmailDomainService.js";
const EmailDomain = EmailDomainService.build();

import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();

import ModalValidarRemetente from "@/components/Configuracoes/Modal/ModalValidarRemetente";

export default {
  components: {
    ModalCriarRemetentes,
    ModalEditarRemetente,
    Paginate,
    ModalValidarRemetente
  },
  data() {
    return {
      isSendgrid: false,
      remetentes: [],
      sender: [],
      selectedRemetente: {},
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      total: 0,
      selectedSender: {}
    };
  },
  methods: {
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    verifyEmailProvider(){
      serviceGlobalSettings
      .read({id: "email_provider"})
      .then((resp) => {
        if(resp.meta_value && resp.meta_value == 'sendgrid'){
          this.isSendgrid = true;
        }
      });
    },
    filterEmailDomainInArray(sender) {
      let includeBtnDns = true;
      const notAllowedDns = [
          "zoho.com",
          "icloud.com",
          "gmx.com",
          "yahoo.com",
          "fastmail.com",
          "inbox.com",
          "lavabit.com",
          "myspace.com",
          "hotpotmail.com",
          "uol.com",
          "yandex.com",
          "10minutemail.com",
          "protonmail.com",
          "tutanota.com",
          "lockbin.com",
          "aol.com",
          "mail.ru",
          "qq.com",
          "gmail.com",
          "hotmail.com",
          "live.com",
          "outlook.com",
          ".gpages.com",
          ".pagezz.com",
          ".pagezz.net",
          ".gpages.com.br",
      ];
      notAllowedDns.forEach((item) => {
          if (sender && sender.indexOf(item) !== -1) {
              includeBtnDns = false;
          }
      });
      return includeBtnDns;
  },
    sendToVerification(data) {
      if(this.isSendgrid && !this.filterEmailDomainInArray(data.email)){
        this.$grToast.toast("Por favor, cadastre um remetente que tenha um domínio personalizado. Exemplo: exemplo@meudominio.com" , {
          title: "Remetentes",
          variant: "warning",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.selectedSender = data;
      this.$bvModal.show('verifyAddress');
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    filterPhoto(rementente) {
      var metas = rementente.imagepath;
      return metas;
    },
    openModal(data, remetente) {
      this.$bvModal.show(data);
      this.selectedRemetente = remetente;
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetch(page);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetch();
    },
    DeletarRemetente(id) {
      serviceSettings
        .delete(`sender/` + id)
        .then((resp) => {
          this.fetch();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    fetch(page = 1) {
      this.loading = true;
      serviceSettings
        .read(`sender?page=${page}`)
        .then((response) => {
          this.remetentes = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.verifyEmailProvider();
    await this.fetch();
  },
};
</script>
<style lang="scss">
body:has(.swal2-container) {
  .swal2-checkbox {
    display: none !important;
  }
}
</style>
<style scoped>

.status-sender {
  padding: 8px;
  border-radius: 10px;
  background: var(--red2);
  color: var(--red);
  width: fit-content;
  line-height: 1;
}

.active-status {
  background: var(--greenn2);
  color: var(--greenn);
}
.user-icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.justify-content-center {
  background-color: transparent;
}
.break {
  word-break: break-word;
  margin: 0;
}
input {
  width: 100%;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
</style>

<template>
  <div class="container pt-4">
    <BaseHeader
      title="Configurações do sistema"
      :navigation="navigation"
      class="mb-5"
    ></BaseHeader>
    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border padding"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <!-- Nenhuma das configurações dessa tela é de fato funcional. A tela ficará escondida dos usuários enquanto não forem funcionais. -->
      <ConfigSistemaLead ref="lead"/>
      <ConfigSistemaVendedor ref="vendedor"/>
      <ConfigSistemaProduto ref="produtos"/>
      <ConfigSistemaRemetentes ref="remetentes"/>
      <!-- <ConfigSistemaNotificacao ref="notificacao"/> -->
      <ConfigSistemaPipeline ref="crm"/>
      <ConfigSistemaLGPD ref="lgpd"/>
      <ConfigActivities ref="activities"/>
      <div
        key="loading"
        v-if="loading"
        class="py-5 my-5 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"> </b-spinner>
      </div>
    </b-tabs>
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>
<script>
import ConfigSistemaLead from "@/components/Configuracoes/ConfigSistemaLead.vue";
import ConfigSistemaVendedor from "@/components/Configuracoes/ConfigSistemaVendedor.vue";
import ConfigSistemaProduto from "@/components/Configuracoes/ConfigSistemaProduto.vue";
import ConfigSistemaRemetentes from "@/components/Configuracoes/ConfigSistemaRemetentes.vue";
import ConfigSistemaNotificacao from "@/components/Configuracoes/ConfigSistemaNotificacao.vue";
import ConfigSistemaPipeline from "@/components/Configuracoes/ConfigSistemaPipeline.vue";
import ConfigSistemaLGPD from "@/components/Configuracoes/ConfigSistemaLGPD.vue";
import ConfigActivities from "@/components/Configuracoes/ConfigActivities.vue";

export default {
  components: {
    ConfigSistemaLead,
    ConfigSistemaVendedor,
    ConfigSistemaProduto,
    ConfigSistemaRemetentes,
    ConfigSistemaNotificacao,
    ConfigSistemaPipeline,
    ConfigSistemaLGPD,
    ConfigActivities,
  },
  data() {
    return {
      navigation: [
        { link: "Configurações do sistema", to: this.$route.fullPath },
      ],
      loading: false,
      level: this.$store.getters.user.user.level,
    };
  },
  mounted() {
    if (
      this.level !== "owner" &&
      this.level !== "admin" &&
      this.level !== "commercial_leader"
    ) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    if (!this.$router.currentRoute.query.tab || this.$router.currentRoute.query.tab == 'email') {
      this.$refs.remetentes.fetch()
    } else {
      this.$refs[this.$router.currentRoute.query.tab].fetch()
    }
  },
};
</script>

<style scoped>
.TabHeader .nav-item a {
  padding: 15px 34px !important;
}
</style>

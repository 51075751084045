<template>
  <b-tab
    :title="`CRM`"
    :active="$route.query.tab === 'crm'"
    @click="setQuery('crm')"
    @click.once="fetch()"
  >
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading" style="position: relative;" v-if="!disappear || $store.getters.recursos.ncrm">
      <div v-if="!$store.getters.recursos.ncrm" class="__blocked">
        <svg fill="#4ea934" height="150px" width="150px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
        <span style="color: inherit; " class="title">Acesso bloqueado</span>
        <span style="font-size: 14px; color: inherit; opacity: 0.7;">Esse recurso ainda não está incluso em seu plano,<br> fale com nosso time comercial para mais informações.</span>
        <button @click="upgradePlan()">
          Fazer upgrade 
        </button>
      </div>
      <h5 class="my-3" @mousemove.once="$store.getters.recursos.ncrm ? '' : disappear = true">Configuração do CRM</h5>
      <div class="grid" @mousemove.once="$store.getters.recursos.ncrm ? '' : disappear = true">
        <div class="acao">
          <div class="telefone">
            <!-- {{ telefone }} -->
            <h4 class="titulo">Telefone</h4>
            <b-form-checkbox v-model="telefone" name="is_main" size="lg" switch>
              <p class="info-checkbox" style="width: 0px">
                {{ telefone ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa/Desativa a visualização do telefone dos leads."
          />
        </div>
        <div class="acao">
          <div class="tag-do-Lead">
            <h4 class="titulo">Tag do lead</h4>
            <b-form-checkbox v-model="tag_lead" name="is_main" size="lg" switch>
              <p class="info-checkbox" style="width: 0px">
                {{ tag_lead ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa/Desativa a visualização da tag dos leads."
          />
        </div>
        <div class="acao">
          <div class="adicionar-tag">
            <!-- {{ add_tag }} -->
            <h4 class="titulo">Adicionar tag</h4>
            <b-form-checkbox v-model="add_tag" name="is_main" size="lg" switch>
              <p class="info-checkbox" style="width: 0px">
                {{ add_tag ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Adicionar a tag do board ao movimentar o lead dentro do CRM."
          />
        </div>
        <div class="acao">
          <div class="cor-tag">
            <!-- {{ cor_tag }} -->
            <h4 class="titulo">Follow-up</h4>
            <b-form-checkbox
              v-model="follow_lead"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ follow_lead ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa/Desativa a visualização do follow-up dos leads."
          />
        </div>
        <div class="acao">
          <div class="cor-tag">
            <!-- {{ cor_tag }} -->
            <h4 class="titulo">Empresas</h4>
            <b-form-checkbox
              v-model="companies_value"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ companies_value ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa/Desativa a visualização da empresa dos leads."
          />
        </div>
        <div class="acao">
          <div class="titulo-produto">
            <!-- {{ titulo_produto }} -->
            <h4 class="titulo">Valor do lead</h4>
            <b-form-checkbox
              v-model="value_lead"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ value_lead ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa/Desativa a visualização de valor do Produto."
          />
        </div>
        <div class="acao">
          <div class="mudar_tag">
            <h4 class="titulo">Motivos de perda de lead</h4>
            <button
              style="
                padding: 5px 10px;
                border: none;
                border-radius: 10px;
                color: white;
                background-color: var(--greenn);
              "
              @click="openModal('ModalReasons')"
            >
              Gerenciar
            </button>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Gerencie os motivos padrões para justificar quando um lead é perdido"
          />
        </div>

        <div class="acao">
          <div class="mudar_tag">
            <h4 class="titulo">Motivos de ganho de lead</h4>
            <button
              style="
                padding: 5px 10px;
                border: none;
                border-radius: 10px;
                color: white;
                background-color: var(--greenn);
              "
              @click="openModal('ModalGainReasons')"
            >
              Gerenciar
            </button>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Gerencie os motivos padrões para justificar quando um lead é ganho"
          />
        </div>
      </div>
      <b-row class="mt-2" @mousemove.once="$store.getters.recursos.ncrm ? '' : disappear = true">
        <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary" @click="updateAllSettings"
            >Salvar Alterações</BaseButton
          >
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <ModalReasons/>
    <ModalGainReasons/>
    </b-tab>
  </b-tab>
</template>

<script>
import ModalReasons from "@/components/Configuracoes/Modal/ModalReasons";
import ModalGainReasons from "@/components/Configuracoes/Modal/ModalGainReasons";
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
export default {
  components: {
    ModalReasons,
    ModalGainReasons
  },
  data() {
    return {
      disappear: false,
      telefone: true,
      tag_lead: true,
      add_tag: true,
      follow_lead: true,
      companies_value: true,
      value_lead: true,
      loading: false,
      recursos: this.$store.getters.recursos
    };
  },
  methods: {
    upgradePlan() {
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
        "do",
        "message:send",
        ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
      ]);
    },
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    updateAllSettings() {
      if (this.error) {
        this.$grToast.toast("Erro ao integrar", {
          title: "Configuração de Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.createdTelefone();
        this.createdTagLead();
        this.createdAddTag();
        this.createdFollow();
        this.createCompanies();
        this.createdValue();
        this.$grToast.toast("Alterações salvas com sucesso", {
          title: "CRM",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
    },
    createdValue() {
      var data = {
        meta_key: "value_lead",
        meta_value: this.value_lead + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createCompanies(){
      var data = {
        meta_key: "companies_value",
        meta_value: this.companies_value + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdFollow() {
      var data = {
        meta_key: "follow_lead",
        meta_value: this.follow_lead + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdAddTag() {
      var data = {
        meta_key: "add_tag_pipeline",
        meta_value: this.add_tag + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdTagLead() {
      var data = {
        meta_key: "tag_lead_pipeline",
        meta_value: this.tag_lead + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdTelefone() {
      var data = {
        meta_key: "phone_pipeline",
        meta_value: this.telefone + "",
      };
      serviceSettings
        .create(data)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    redirect() {
      this.$router.push("/dynamicRoute/planos-2021");
    },
    fetch() {
      this.loading = true;
      var data = {
        id: "pipeline",
      };
      serviceSettings
        .read(data)
        .then((resp) => {
          if (resp) {
            for (let i = 0; i < resp.length; i++) {
              const pipe = resp[i];
              if (pipe.meta_key == "phone_pipeline") {
                this.telefone = pipe.meta_value;
              } else if (pipe.meta_key == "tag_lead_pipeline") {
                this.tag_lead = pipe.meta_value;
              } else if (pipe.meta_key == "add_tag_pipeline") {
                this.add_tag = pipe.meta_value;
              } else if (pipe.meta_key == "follow_lead") {
                this.follow_lead = pipe.meta_value;
              } else if (pipe.meta_key == "value_lead") {
                this.value_lead = pipe.meta_value;
              }else if(pipe.meta_key == "companies_value"){
                this.companies_value = pipe.meta_value
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonPipeline {
  border: none;
  background: var(--greenn);
  color: #fff;
  outline: none !important;
  font-weight: 600;
  height: 60px;
  padding: 0 42px !important;
  border-radius: 10px !important;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:hover {
    transform: scale(1.07);
  }
  &:focus {
    box-shadow: none;
  }
}

.acao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 50px;
}
input {
  width: 100%;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
</style>

<template>
  <BaseModal
    name="ModalReasons"
    id="ModalReasons"
    idModal="ModalReasons"
    size="sz"
    :title="`Motivos de perda de lead: ${totalReasons}`"
    @shown="openModal"
  >
    <div ref="reasonsScroll" class="content-reasons">
      <div v-for="(item, idx) in reasons" :key="idx">
        <div class="p-1" style="position: relative; ;">
          <div class="separador" style="position:absolute; top: 17px; left: -3px;"></div>
          <div style="display: grid; grid-template-columns: 1fr 18px; gap: 10px;">
            <div class="select" >
              <input style="margin: 4px 0 !important;" :style="reasons[idx].length > 100 ? 'border-color: var(--red) !important;' : ''" type="text" v-model="reasons[idx].reason">
            </div>
            <div class="image-control">
              <div>
                <img
                  :class="{ 'disabled-arrow' : idx == 0}"
                  class="first-arrow"
                  src="@/assets/editor/chevron-down.svg"
                  @click="idx == 0 ? '' : moveUp(idx)"
                  title="Mover para cima"
                  style="margin-top: 0px;"
                />
                <button
                  class="remove-btn"
                  @click="removeItem(idx,item.id)"
                >✖</button>
                <img
                  :class="{ 'disabled-arrow' : idx + 1 == reasons.length}"
                  class="last-arrow"
                  src="@/assets/editor/chevron-down.svg"
                  @click="idx + 1 == reasons.length ? '' : moveDown(idx)"
                  title="Mover para baixo"
                  style=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="add-reason" @click="addReason()">+</button>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" :disabled="reasonsEmpty"  @click="onSubmit">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
export default {
  data() {
    return {
      reasons: [],
      totalReasons: 0,
      page: 0
    };
  },
  computed: {
    reasonsEmpty() {
      return (this.reasons.filter(reason=> reason.reason.trim() == '').length) >= 1
    }
  },
  methods: {
    openModal(){
      this.reasons = [];
      this.page = 0;
      this.getReasonLoss();
    },
    getReasonLoss(page = 1){      
      let data = {
        'id':`reasons/list?page=${page}`,
        'per_page': 15
      }

      serviceSettings.read(data).then((resp) => {
        if(!resp.data.length){
          if(page > 1){
            this.$refs.reasonsScroll.removeEventListener('scroll', this.handleScroll);
            return;
          }

          this.$grToast.toast("Nenhum motivo encontrado", {
            title: "Gerenciar motivos",
            variant: "warning",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }

        let concatReasons = this.reasons.concat(resp.data);
        
        this.reasons = concatReasons.sort((a, b) => a.position - b.position);

        if(page === 1){
          this.page = 2;
          this.totalReasons = resp.total;
          this.$refs.reasonsScroll.addEventListener('scroll', this.handleScroll);
          return;
        }
        this.page += 1;
      });
    },
    onSubmit() {
      if (this.reasons.some((reason) => !reason)) {
        this.$grToast.toast("Preencha todos os campos", {
          title: "Gerenciar motivos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.reasons.some((reason) => reason > 100)) {
        this.$grToast.toast("Limite de 100 caracteres", {
          title: "Gerenciar motivos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      for (let i = 0; i < this.reasons.length; i++) {
        let item = this.reasons[i];
        let data = {
          id:`reasons/save`,
          reasonId:item.id,
          position: i,
          reason:item.reason
        }
        serviceSettings
          .createId(data)
          .then(() => {
            if(this.reasons.length-1 == i){
              this.$grToast.toast("Motivos salvos com sucesso", {
                title: "Gerenciar motivos",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            }
          })
          .catch((err) => {});
        }
        
        this.$bvModal.hide("ModalReasons");
    },
    moveUp(idx) {
      var moving = this.reasons[idx]
      this.reasons.splice(idx, 1);
      this.reasons.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.reasons[idx]
      this.reasons.splice(idx, 1);
      this.reasons.splice(idx + 1, 0, moving);
    },
    removeItem(index,id) {
      let data = {
          id:`reasons/${id}`
        }
        serviceSettings
          .destroy(data).then((resp) => {
              this.$grToast.toast("Motivos deletados com sucesso", {
              title: "Gerenciar motivos",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
              });
          })
      this.reasons.splice(index, 1);
    },
    addReason() {
      this.reasons.push({reason:''})
    },
    handleScroll(){
      const el = this.$refs.reasonsScroll;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        this.getReasonLoss(this.page);
      }
    }
  },
  beforeDestroy() {
    if(this.$refs.reasonsScroll){
      this.$refs.reasonsScroll.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style scoped lang="scss">
.content-reasons {
  min-height: 390px;
  max-height: 390px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 0 5px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--white-dark);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}
.add-reason {
  border: 1px solid var(--white-medium);
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
  font-size: 25px;
  transition: all 0.3s;
  margin: 10px 0 0;
  &:hover {
    color: var(--greenn);
    border-color: var(--greenn);
  }
}
input {
  width: 100%;
}
.container-flex {
  display: flex;
  flex-direction: column;
}
.image-control {
  height: auto;
  display: flex;
  width: 18px;
  align-items: center;
  justify-content: center;
  button {
    height: 18px;
    width: 18px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 25px;
    color: var(--red);
    transition: color 0.3s;
    font-size: 14px;
    cursor: pointer;
    img {
      height: 15px;
    }
  }
  .disabled-btn {
    color: var(--white-dark);
  }
  div {
    width: min-content;
  }
  .first-arrow {
    rotate: 180deg;
    height: 18px;
    filter: brightness(2);
  }
  .last-arrow {
    rotate: 0deg;
    height: 18px;
  }
  img {
    cursor: pointer;
  }
  .disabled-arrow {
    filter: invert(50%);
    cursor: default !important;
  }
}
</style>

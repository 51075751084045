<template>
    <b-modal @shown="verifyLoading = false" name="verifyAddress" id="verifyAddress" idModal="verifyAddress" size="md"
        title="Verifique o remetente" :hide-header="true" :hide-footer="true" centered>
        <div class="container-flex">
            <h2>Remetentes</h2>
            <p class="my-4">{{ (isSendgrid || !filterEmailDomainInArray(sender.email)) ? "Por favor, valide seu remetente" : "Deseja validar de qual forma?"
                }}</p>
        </div>
        <div style="display: flex; gap: 15px; justify-content: center;">
            <BaseButton :disabled="verifyLoading" style="padding: 0 28px!important;" variant="link-info"
                @click="(isSendgrid ||  !filterEmailDomainInArray(sender.email)) ? closeModal() : sendVerificationEmailSes(sender.email)">
                {{ (isSendgrid ||  !filterEmailDomainInArray(sender.email))  ? "Voltar" : "Por remetente" }}
            </BaseButton>


            <BaseButton v-if="isSendgrid || !isSendgrid &&  filterEmailDomainInArray(sender.email)" :disabled="verifyLoading" style="padding: 0 28px!important;" variant="primary"
                @click="callfunction">
                {{ (isSendgrid)  ? "Validar por domínio" : "Por domínio (recomendado)" }}
            </BaseButton>

            <BaseButton v-if="!isSendgrid && !filterEmailDomainInArray(sender.email)" :disabled="verifyLoading" style="padding: 0 28px!important;" variant="primary"
                @click="sendVerificationEmailSes(sender.email)">
                Por remetente
            </BaseButton>
        </div>
    </b-modal>
</template>

<script> 
import Vue from "vue";
import EmailDomainService from "@/services/resources/EmailDomainService.js";
const emailDomain = EmailDomainService.build();
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
import { listaApontamentoSes } from "@/mixins/domainPonintSes"
export default {
    props: {
        isSendgrid: {
            type: Boolean,
            default: false
        },
        sender: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            verifyLoading: false,
        };
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("verifyAddress");
        },
        sendVerificationEmailSes(email) {
            if (this.isSendgrid) {
                return;
            }
            this.verifyLoading = true;
            serviceEmail
                .createId({ id: "verify-sender", email: email, verify: true })
                .then((resp) => {
                    Vue.swal({
                        title: "Remetentes",
                        icon: 'info',
                        html: `<p style="font-size: 16px !important; color: #81858e !important; line-height: 1.5 !important;">Você irá receber um e-mail de confirmação da <span style="font-weight: 600;">Amazon Web Services</span> para o remetente ${email}. Por favor, confirme o email.</p>`,
                        type: "danger",
                        showCancelButton: true,
                        confirmButtonText: "Já confirmei",
                        cancelButtonText: "Fechar",
                        customClass: "sweet-container",
                        confirmButtonClass: "button button-primary mt-3 mb-3",
                        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
                        buttonsStyling: false,
                        reverseButtons: true,
                    }).then((result) => {
                        this.verifyLoading = false;
                        if (result.isConfirmed) {
                            if (resp.status && resp.status == "Success" || resp.message && resp.message == "Remetente já cadastado") {
                                this.verifyLoading = false;
                                this.$grToast.toast("Remetente verificado com sucesso!", {
                                    title: "Remetentes",
                                    variant: "success",
                                    autoHideDelay: 5000,
                                    appendToast: true,
                                });
                                this.$emit("getSenders");
                            } else {
                                serviceEmail
                                    .createId({ id: "verify-sender", email: email, verify: true })
                                    .then((resp) => {
                                        if (resp.status == "Não verificado") {
                                            this.$grToast.toast(
                                                "Remetente ainda não verificado!",
                                                {
                                                    title: "Remetentes",
                                                    variant: "danger",
                                                    autoHideDelay: 5000,
                                                    appendToast: true,
                                                }
                                            );
                                            return;
                                        }
                                        this.$grToast.toast("Remetente verificado com sucesso!", {
                                            title: "Remetentes",
                                            variant: "success",
                                            autoHideDelay: 5000,
                                            appendToast: true,
                                        });
                                        this.$emit("getSenders");
                                    })
                                    .catch((err) => {
                                        this.$grToast.toast(
                                            "Erro ao verificar remetente!",
                                            {
                                                title: "Remetentes",
                                                variant: "danger",
                                                autoHideDelay: 5000,
                                                appendToast: true,
                                            }
                                        );
                                    })
                                    .finally(() => {
                                        this.verifyLoading = false;
                                        this.$bvModal.hide("verifyAddress");
                                    })
                            }
                        }
                    });
                })
                .catch((err) => {
                    this.$grToast.toast(
                        "Erro ao verificar remetente!",
                        {
                            title: "Remetentes",
                            variant: "danger",
                            autoHideDelay: 5000,
                            appendToast: true,
                        }
                    );
                })
                .finally(() => {
                    this.verifyLoading = false;
                    this.$bvModal.hide("verifyAddress");
                })
        },
        callfunction(){
            listaApontamentoSes(this, this.sender.email.split("@")[1])
        },
        domainFinishSes(domain) {
            emailDomain.post("/check-note/", { domain }).then(
                (dados) => {
                    let msg = "";
                    let type = "";
                    let title = "";
                    if (!dados.status) {
                        msg =
                            "Alguns provedores de DNS podem demorar até 24 horas para propagar.";
                        type = "warning";
                        title = "Atenção";
                    } else {
                        msg = "O domínio foi verificado com sucesso.";
                        type = "success";
                        title = "Sucesso";
                    }
                    this.$swal({
                        icon: type,
                        title: title,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                        confirmButtonClass: "button button-primary mt-3 mb-3",
                        buttonsStyling: false,
                        text: msg,
                    });
                },
                (error) => {
                    this.$grToast.toast("Falha ao verificar!", {
                        title: "Remetentes",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: true,
                    }
                    );
                }
            ).finally(() => {
                this.$emit("getSenders");
            });
        },
        filterEmailDomainInArray(sender) {
            let includeBtnDns = true;
            const notAllowedDns = [
                "zoho.com",
                "icloud.com",
                "gmx.com",
                "yahoo.com",
                "fastmail.com",
                "inbox.com",
                "lavabit.com",
                "myspace.com",
                "hotpotmail.com",
                "uol.com",
                "yandex.com",
                "10minutemail.com",
                "protonmail.com",
                "tutanota.com",
                "lockbin.com",
                "aol.com",
                "mail.ru",
                "qq.com",
                "gmail.com",
                "hotmail.com",
                "live.com",
                "outlook.com",
                ".gpages.com",
                ".pagezz.com",
                ".pagezz.net",
                ".gpages.com.br",
            ];
            notAllowedDns.forEach((item) => {
                if (sender && sender.indexOf(item) !== -1) {
                    includeBtnDns = false;
                }
            });
            return includeBtnDns;
        },
    },
};
</script>

<style lang="scss" scoped>
.container-flex {
    display: grid;
    justify-items: center;
    text-align: center;
    margin-bottom: 20px;

    h2 {
        margin: 0 0 0.4em;
        padding: 0;
        color: #595959;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word;
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }

    p {
        color: var(--gray03);
        font-size: 16px;
        line-height: 1.5;
        font-size: 16px;
    }

    span {
        font-weight: 600;
        font-size: 20px;
    }
}
</style>
<template>
  <b-tab :title="`Notificação`">
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
      <h5 class="my-3">Configuração de notificação</h5>
      <div class="flex">
        <h4 class="titulo">
          Notificar quando o lead atingir a temperatura de
        </h4>
        <input type="number" v-model="graus" />
        <h4 class="titulo">graus</h4>
        <div class="checkbox">
          <b-form-checkbox v-model="graus_acao" name="is_main" size="lg" switch>
            <p class="info-checkbox" style="width: 0px"></p>
          </b-form-checkbox>
        </div>
      </div>
      <div class="flex">
        <h4 class="titulo">Quando o lead aumentar sua temperatura em</h4>
        <input type="number" v-model="graus_aumentar" />
        <h4 class="titulo">graus no periodo de</h4>
        <input type="number" v-model="horas" />
        <h4 class="titulo">horas</h4>
        <div class="checkbox2">
          <b-form-checkbox
            v-model="graus_acao_aumentar"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px"></p>
          </b-form-checkbox>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary">Salvar alterações</BaseButton>
        </b-col>
      </b-row>
    </b-container>
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-tab>
</template>

<script>
export default {
  data() {
    return {
      graus: 0,
      graus_acao: true,
      graus_aumentar: 0,
      graus_acao_aumentar: true,
      horas: 0,
      loading: false,
    };
  },
};
</script>

<style scoped>
.checkbox2 {
  position: absolute;
  right: 283px;
  top: 15px;
}
.checkbox {
  position: absolute;
  right: 507px;
  top: 16px;
}
input {
  width: 90px;
}
.flex {
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
</style>

<template>
    <div>
    <ConfigSistema/>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import ConfigSistema from '@/components/Configuracoes/ConfigSistema.vue';


export default {
  components: {
    BaseView,
    ConfigSistema
  },
  created() {
    // if (this.$store.getters.user.user.level !== 'admin') {this.$router.push("/dynamicRoute/AccessDenied")};
  },
}
</script>